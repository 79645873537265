<template>
  <svg
    class="fill-current"
    viewBox="0 0 1000 206"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M224.8 205.72c24.69 0 40.43-15.83 40.43-38.8V38.66c0-23-15.74-38.66-40.43-38.66s-40.43 15.69-40.43 38.66v128.26c0 22.97 15.74 38.8 40.43 38.8Z"
    />
    <path
      d="M133.41 0c-19.06 0-28.12 6-36.87 19.45l23.28 48.42A84.9 84.9 0 0 0 133.1 87c.28.28.92 1 .93 1a4.29 4.29 0 0 1 .76 3.51 4.36 4.36 0 0 1-2.79 3.1 4.19 4.19 0 0 1-3.39-.25 8.49 8.49 0 0 1-1.26-.88c-4.69-4.19-12-13.43-16.25-22.39L93.89 35c-10.31-21.7-20-35-45.77-35C20.46 0 0 16.93 0 42.12v130c0 19.74 16.87 33.59 38.43 33.59 21.11 0 34.44-10.28 38.13-24.66a32.51 32.51 0 0 0 .93-8.05v-9l-18.75-41.13c-3.47-8.33-10.53-16.35-13.62-19.77a4.3 4.3 0 0 1-1.33-3.88 4.37 4.37 0 0 1 3.88-3.6 4.19 4.19 0 0 1 1.87.25 7.74 7.74 0 0 1 2 1.25 69 69 0 0 1 15.78 22.39l23.75 52.62c9.06 20 17.65 33.59 44.52 33.59 21.71 0 38.59-13.85 38.59-33.59v-130C174.19 16.93 160 0 133.41 0Z"
    />
    <path
      d="M438.15 0c-24.37 0-37.8 15.53-51.39 50.66a166.74 166.74 0 0 0 13.74 24.07 4.62 4.62 0 0 1 .62 3.79 4.59 4.59 0 0 1-7.8 1.9c-1-1.27-2.27-3.24-3.59-5.25a156.68 156.68 0 0 1-9.85-17.37L369.57 35c-9.68-21.56-20.46-35-46.24-35-28.12 0-48.11 16.93-48.11 42.12v130c0 19.74 16.87 33.59 38.58 33.59 23.33 0 37.86-12.38 39.39-30.52a41.51 41.51 0 0 1-9.21-8.61c-7-8.85-9.79-19.31-10.72-26.19a5.68 5.68 0 0 1 .11-1.94 4.52 4.52 0 0 1 8.84.73c.81 5.92 3.22 14.65 8.86 21.8 5.49 7 14.21 12.68 28.44 12.68s23.05-5.73 28.58-12.69c5.68-7.15 7.49-15.95 8.71-21.67.08-.39.2-.92.22-1a4.49 4.49 0 0 1 4.27-3.14 4.38 4.38 0 0 1 4.31 3.28 6.1 6.1 0 0 1 0 2.12c-.87 7-3.45 17.16-10.47 26a42 42 0 0 1-8.62 8.16c1.32 18.38 15.89 31 39.41 31 21.72 0 38.59-13.85 38.59-33.59v-130C484.55 16.93 464.56 0 438.15 0Z"
    />
    <path
      d="M641.73 106.36c-6.56 2.8-14.37 5.88-19.21 7a54.92 54.92 0 0 1-9.06 1.82c-.23 0-.75-.07-.79-.07a4.16 4.16 0 0 1-1.24-7.87l.43-.18a12.73 12.73 0 0 1 1.91-.56c30.78-5.88 45.8-21.55 45.8-46.46 0-34-30.65-57.94-76.89-57.94h-48.89c-23.44 0-39.06 14.41-39.06 36.11v129.31c0 21.69 15.47 36.1 39.06 36.1h48.89c48 0 78.74-23.23 78.74-59.34 0-17.21-7.19-29.94-19.69-37.92Zm-61.23 18.33v33.36a4.66 4.66 0 0 1-.26 1.46.65.65 0 0 1 0 .13 4.25 4.25 0 0 1-.21.49 4.92 4.92 0 0 1-.24.45c0 .08-.11.15-.16.22a2.74 2.74 0 0 1-.34.41 4.63 4.63 0 0 1-6.88 0 2.64 2.64 0 0 1-.34-.41 1.77 1.77 0 0 1-.16-.22 3.88 3.88 0 0 1-.24-.45 4.25 4.25 0 0 1-.21-.49.65.65 0 0 1 0-.13 4.71 4.71 0 0 1-.26-1.46v-33.74a4.53 4.53 0 0 1 .25-1.44l.06-.18a3.79 3.79 0 0 1 .18-.42 3.25 3.25 0 0 1 .28-.52l.11-.16a3.73 3.73 0 0 1 .36-.43 4.63 4.63 0 0 1 6.9 0 3.54 3.54 0 0 1 .35.42l.12.17a3.15 3.15 0 0 1 .28.51 3.38 3.38 0 0 1 .18.44l.06.16a4.65 4.65 0 0 1 .25 1.45Zm0-66.67v23.91a4.82 4.82 0 0 1-4.23 4.87 4.69 4.69 0 0 1-5.15-4.67V57.64a4.52 4.52 0 0 1 .25-1.43l.06-.19c.05-.14.11-.27.17-.41a4.68 4.68 0 0 1 .3-.54l.1-.07a2.89 2.89 0 0 1 .38-.47 4.65 4.65 0 0 1 6.88 0 4.22 4.22 0 0 1 .38.46l.09.13a4.68 4.68 0 0 1 .3.54c.06.14.12.27.17.41l.06.19a4.52 4.52 0 0 1 .25 1.43Z"
    />
    <path
      d="M796.49 0c-24.13 0-39.85 15.61-39.85 38.48v103.09a4.61 4.61 0 0 1-3.08 4.41 4.69 4.69 0 0 1-6.26-4.41V38.5c0-22.87-15.72-38.5-39.85-38.5-24.29 0-39.85 15.63-39.85 38.5v89.25c0 45.74 33.47 78 84.37 78s84.37-32.22 84.37-78V38.5c0-22.87-15.56-38.5-39.85-38.5Z"
    />
    <path
      d="M965.64 78.07c-16.12.14-23.59-1.12-28.72-2.28-12.14-2.7-24-6.39-29.15-8.66a11.75 11.75 0 0 1-1.23-.61 4 4 0 0 1-2.18-3.52 3.71 3.71 0 0 1 3.35-3.86 5.64 5.64 0 0 1 1.25 0c2.51.31 3.38.87 7.86 2.45A189.76 189.76 0 0 0 940.66 68a126.19 126.19 0 0 0 27 2c18.76-.33 31.89-12.09 31.58-29.72-.39-22.5-28.78-41-77.76-40.18-52.08.9-77.7 34.26-77.08 69.81.45 25.55 13.16 43.1 28.56 54.78 16.12-.28 24.82 1 30.11 2.11 12.3 2.85 24.34 6.46 29.15 8.66a12.51 12.51 0 0 1 1.75 1 4 4 0 0 1 1.75 3.33 3.68 3.68 0 0 1-3.81 3.83h-.18c-2.6-.14-6.57-1.7-8.65-2.46a194.8 194.8 0 0 0-23.59-6.48c-8.87-1.79-18.18-2.05-27-1.89-18.76.32-32.18 13.06-31.87 30.83.47 26.8 37.34 42.83 81.83 42 53.63-.93 78.18-33.73 77.55-69.83-.48-27.06-18.07-48.7-34.36-57.72Z"
    />
    <g v-if="props.registered">
      <path
        d="M999.22 191.83a10.12 10.12 0 0 0-5.33-5.33 10.09 10.09 0 0 0-7.78 0 10.12 10.12 0 0 0-5.33 5.33 10.09 10.09 0 0 0 0 7.78 9.92 9.92 0 0 0 2.15 3.19 10.21 10.21 0 0 0 3.18 2.15 10.21 10.21 0 0 0 7.78 0 10.21 10.21 0 0 0 3.18-2.15 9.92 9.92 0 0 0 2.15-3.19 10.09 10.09 0 0 0 0-7.78Zm-2.65 7.72a7.79 7.79 0 0 1-2.75 2.75 7.66 7.66 0 0 1-7.64 0 7.79 7.79 0 0 1-2.75-2.75 7.62 7.62 0 0 1 0-7.65 7.71 7.71 0 0 1 2.75-2.75 7.6 7.6 0 0 1 7.64 0 7.71 7.71 0 0 1 2.75 2.75 7.62 7.62 0 0 1 0 7.65Z"
      />
      <path
        d="M993.51 195.67A3 3 0 0 0 994 194a2.81 2.81 0 0 0-.47-1.66 2.9 2.9 0 0 0-1.13-1 2.75 2.75 0 0 0-1.23-.32h-4.67v9.22h2v-3.14h2l1.62 3.12h2.22l-1.89-3.59a3.34 3.34 0 0 0 1.06-.96Zm-5-3.17h1.93a1.65 1.65 0 0 1 .84.3 1.25 1.25 0 0 1 .48 1.15 1.52 1.52 0 0 1-.48 1.23 1.42 1.42 0 0 1-.89.42h-1.88Z"
      />
    </g>
  </svg>
</template>

<script lang="ts" setup>
interface SvgNimbusLogotypeProps {
  registered?: boolean
}

const props = withDefaults(defineProps<SvgNimbusLogotypeProps>(), { registered: false })
</script>
