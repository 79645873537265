<template>
  <ClientOnly>
    <VTooltip
      class="inline-flex items-center"
      placement="top-start"
      theme="white"
      :disabled="!$slots.tooltip"
      :arrow-overflow="false"
    >
      <BaseBadge class="bg-green text-xs"
        >Save<span v-if="props.value">&nbsp;{{ props.value }}%</span
        ><SvgGlyphHelp v-if="$slots.tooltip" class="-mt-0.5 ml-1 inline-block"
      /></BaseBadge>
      <template #popper>
        <slot name="tooltip" />
      </template>
    </VTooltip>
  </ClientOnly>
</template>

<script lang="ts" setup>
interface ProductSaveBadgeProps {
  value?: number | string
}

const props = defineProps<ProductSaveBadgeProps>()
</script>

<style lang="postcss">
.v-popper--theme-white .v-popper__inner {
  @apply max-w-[280px] rounded-[10px] border border-black bg-white px-5 py-4 text-left text-[16px]/[20px] text-black;
}

.v-popper--theme-white .v-popper__arrow-outer {
  @apply hidden;
}
</style>
